#lineup {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#lineup .body {
  padding-bottom: 100px;
  max-width: 800px;
}


#lineup h2 {
  background-color: #d90000;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-weight: 600;
  margin-bottom: 80px;
}


#lineup .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#lineup .animatesTriangleLeft {
  position: absolute;
  left: -750px;
  top: 00px;
}


#lineup .animatesTriangleRight {
  position: absolute;
  top: -200px;
  right: 0px;
  overflow: hidden;
  width: 400px;
}

#lineup .artist-bar  {
  padding-bottom: 100px;
}

#lineup .artist-bar img {
  max-width: none;
  max-width: 100%;
  width: auto;
  margin-bottom: 20px;
  max-height: 306px;
}

#lineup .artist-bar a {
  text-decoration: none;
  color: inherit;
}

#lineup .artist-bar h3 {
  margin-bottom: 30px;
}

#lineup .artist-bar h3::after {
  content: '';
  display: block;
  height: 4px;
  background-color: #d90000;
  width: 70px;
  margin: auto;
  margin-top: 8px;
}
