
@import url("https://use.typekit.net/wtf3cvg.css");

body {
   font-family: proxima-nova, sans-serif;
   padding-top: 80px;
   overflow: visible!important;
}

body.modal-open {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden!important;
}


h1, h2, h3, h4, h5, h6 {
  font-family: futura-pt, sans-serif;
}

h1 {
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.25rem;
  text-align:  center;
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}


.hide {
  display: none;
}

body a {
  color: #d90000;
}

body a:hover,
body a:focus,
body a:active,
body a:visited {
  color: #d90000;
}


.btn {
  font-family: futura-pt, sans-serif;
}

body .btn:hover,
body .btn:focus,
body .btn:active,
body .btn:visited {
  background-color: #d90000;
  outline: none!important;
  box-shadow: none!important;
}

#top-bar .dropdown ul {
  min-width: 0px;
  right: 10px!important;
  left: auto!important;
}

#top-bar .dropdown>button {
  background: #000;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 38px;



  float: left;
    background: none;
    border: none;
    color: #fff!important;
    font-family: futura-pt, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.125rem;
    font-size: 1.125rem!important;
    outline: none!important;
    box-shadow: none!important;
}

#top-bar .dropdown>button .before {
  color: #d90000;
  font-size: 1.125rem;
  padding-right: 10px;
  position: relative;
  top: 2px;
}

#top-bar {
  background-color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  z-index: 2;
}

#top-bar .row>div {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
}

#top-bar .row>div:nth-child(1) {
  justify-content: flex-start;
}

#top-bar .row>div:nth-child(1) img {
  max-width: 30px;
}

#top-bar .row>div:nth-child(3) {
  justify-content: flex-end;
}

#top-bar .logo {
  width: 150px;
  margin: auto;
}

#top-bar .btn-ticket {
  float: left;
  background-color: #000;
  border: none;
  color: #fff!important;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.125rem;
  font-size: 1.125rem!important;
  outline: none!important;
  box-shadow: none!important;
}

#top-bar .btn-ticket svg {
  color: #d90000;
  margin-right: 20px;
  position: relative;
  top: 1px;
}

.page-loader {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000;
}







#content-wrapper {
  max-width: 100%;
  overflow: hidden;
  position: static;
  z-index: 1;
}

#content-wrapper h1 {
  font-weight: 700;
}

.page {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: left;
}

.page  h1 {
  text-align: left;
}

.page .st1 {
  fill: #e20a17!important;
  stroke: #e20a17!important;
}

.animatesTriangleLeft,
.animatesTriangleRight {
  z-index: -1;
}

.page .animatesTriangleLeft {
  position: absolute;
  left: -750px;
  top: 00px;
  z-index: -1;
}


.page .animatesTriangleRight {
  position: absolute;
  top: -200px;
  right: 0px;
  overflow: hidden;
  width: 400px;
  z-index:-1;
}




.footer {
  background-color: #fff;
  position: relative;
  z-index: 1;
}


#footer-nav-bar {
  padding-top: 50px;
  padding-bottom: 50px;
}


#footer-map-bar {
  position: relative;
  height: 350px;
  z-index: 0;
}

#footer-map-bar::after {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  content: '';
  display: block;
  background-image: url(../images/mapGreyBg.jpeg);
  background-position: center;
  background-size: cover;
  z-index: 0;
  display: none;
}

#footer-map-bar address {
  position: absolute;
    z-index: 1;
    background-color: #d90000;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.125rem;
    margin-left: -400px;
    margin: auto;
    right: 0px;
    left: 0px;
    bottom: -21px;
    max-width: 663px;
}



#footer-map-bar .animatesTriangleLeft svg {
  width: 1300px;
  height: 1300px;
}

#footer-map-bar .animatesTriangleLeft {
  position: absolute;
  left: -800px;
  top: -40px;
  z-index: 1;
  display: none;
}



#footer-partner-bar {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 0;
}

#footer-partner-bar h5 {
  text-transform: uppercase;
  font-size: 1.625rem;
  font-weight: 600;
  letter-spacing: 0.225rem;
  margin-bottom: 50px;
}

#footer-partner-bar .swiper-slide .btn {
  background: none;
  border-radius: 0px;
  color: #d90000;
  border: 2px #d90000 solid;
  text-transform: uppercase;
  font-weight: 600;
}

#footer-partner-bar .swiper-slide {
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 120px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;

  width: 2220px;
  margin-right: 50px;
}

#footer-partner-bar .swiper-slide img {
  max-height: 120px;

}


#footer-time-event {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

#footer-time-event img {
  max-width: 250px;
}



#footer-info-bar {
  background-color: #000;
  padding-top: 40px;
  padding-bottom: 70px;
  color: #fff;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

#footer-info-bar .msg-alert {
  margin-top: 10px;
}

#footer-info-bar .msg-alert p {
  margin-bottom: 0px;
}



#footer-info-bar h6 {
  text-transform: uppercase;
  font-size: 1.325rem;
  font-weight: 600;
  letter-spacing: 0.225rem;
  margin-bottom: 30px;
}



#footer-info-bar .social-bar li {
  border: 1px #fff solid;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 -1px -1px 0;
  transition: all 0.2s var(--circ-in-out);
}

#footer-info-bar .social-bar li:hover {
  background-color: #fff;
  color: #000;
}

#footer-info-bar .social-bar li:hover a {
  color: #000;
}

#footer-info-bar .social-bar li a {
  transition: all 0.5s ease-in;
  font-size: 1.625rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
}


#footer-info-bar svg {}



#footer-info-bar .animatesTriangleLeft svg {
  width: 1100px;
  height: 1100px;
}

#footer-info-bar .animatesTriangleLeft {
  position: absolute;
  left: -650px;
  top: -100px;
}

#footer-info-bar .animatesTriangleRight svg {
  width: 1100px;
  height: 1100px;
}

#footer-info-bar .animatesTriangleRight {
  position: absolute;
  top: -240px;
  right: 0px;
  overflow: hidden;
  width: 400px;
}


#footer-info-bar .newsletter {
  width: 550px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

#footer-info-bar .newsletter input {
  width: 47%;
  border: none;
  background: none;
  border-bottom: 1px #fff solid;
  color: #fff;
  margin-bottom: 20px;
  line-height: 2.125rem;
  outline: none;
  box-shadow: none;
}

#footer-info-bar .newsletter input:nth-child(1) {
  margin-right: 3%;
}

#footer-info-bar .newsletter input:nth-child(2) {
  margin-left: 3%;
}

#footer-info-bar .newsletter button {
  width: 100%;
  border: none;
  background-color: #d90000;
  color: #fff;
  padding: 8px;
  text-transform: uppercase;
  font-weight: 670;
  font-size: 1.325rem;
  letter-spacing: 0.125rem;
}

#footer-info-bar li a {
  color: #fff;
}

#footer-info-bar .term-bar {
  padding-bottom: 10px;
}

#footer-info-bar .term-bar li {
  font-size: 1.025rem;
  padding-left: 10px;
  padding-right: 10px;
}

#footer-info-bar .term-bar li a {
  text-decoration: none;
  color: #6a6a6a;
  transition: all 0.5s ease-in;
}


#footer-info-bar .term-bar li a:hover {
  color: #fff;
}

#footer-info-bar .copyright {
  color: #6a6a6a;
}

#footer-radio {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

#footer-radio>button {
  background-color: #d90000!important;
  border-radius: 0px;
}






/**
 * Block styles
 *
*/
.ticket-bar {
  background-image: url(../images/homeIntroBg.jpeg);
  padding-top: 80px;
  padding-bottom: 60px;
  color: white;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 0;
}


.ticket-bar img {
  max-width: 300px;
  margin-top: -190px
}

.ticket-bar h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.425rem;
  letter-spacing: 0.225rem;
  margin-bottom: 23px;
  margin-top: -15px;
}

.ticket-bar .btn {
  border-radius: 0px;
  border: none;
  background-color: #d90000;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.225rem;
  letter-spacing: 0.125rem;
  color: #fff;
}

.ticket-bar .btn svg {
  margin-right: 10px;
}


.triangle-block {
}

.triangle-block svg {
  width: 1200px;
  height: 1200px;
}

.triangle-block .st0 {
  fill: #E20E17;
  fill-opacity: 1;
}

.triangle-block .st1 {
  fill: #FFFFFF;
  fill-opacity: 1;
}

.triangle-block .st2 {
  fill: #3F3F3F;
  fill-opacity: 1;
}

.triangle-block.animate .st0 {
  fill-opacity: 0;
  stroke: #E20E17;
}

.triangle-block.animate .st1 {
  fill-opacity: 0;
  stroke: #FFFFFF;
}

.triangle-block.animate .st2 {
  fill-opacity: 0;
  stroke: #3F3F3F;
}

.triangle-block.animate .fill,
.triangle-block.animate .fill polygon {
  fill-opacity: 1;
  stroke-width: 0px;
  transition: all 1s ease-in;
}





#partner-modal {
}

#partner-modal h3 {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

#partner-modal h4 {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 1.225rem;
  margin-bottom: 0px;
}


#partner-modal .row h5 {
  text-transform: uppercase;
  font-weight: 700;
}

#partner-modal .row img {
  max-width: 150px;
}

#partner-modal .row>div:nth-child(1) h5 {
  color: #7b7e86;
}

#partner-modal .row>div:nth-child(2) h5 {
  color: #c3a139;
}

#partner-modal .row>div:nth-child(3) h5 {
  color: #bfc1c8;
}


#partner-modal h5.modal-title {
  opacity: 0;
}
