@media (min-width: 1200px) {
  #Terms,
  #imprint,
  #Privacy {
    background-color: #fff;
  }
}

@media (max-width: 1800px) {

}

@media (max-width: 1500px) {

  #home .intro-bar {
    min-height: 400px;
    max-height: 85vh!important;
  }

  #home .stroke-headline-bar-01 div, #home .stroke-headline-bar-01 span, #home .stroke-headline-bar-01 h1,
  #home .stroke-headline-bar-02 div, #home .stroke-headline-bar-02 span, #home .stroke-headline-bar-02 h1 {
    font-size: 2.025rem!important;
  }

  .animatesTriangleRight,
  .animatesTriangleLeft,
  .festival-headline,
  .fullscreen-menu .headline-bar {
    display: none;
  }

  body .fullscreen-menu .menu-bar li a,
  body .fullscreen-menu .menu-bar li {
    font-size: 2.225rem;
  }

  body .fullscreen-menu .close-btn {
    right: 58%;
    top: 50px;
  }
}

@media (max-width: 1300px) {

}

@media (max-width: 1199px) {

  body #footer-partner-bar .swiper-slide .btn {
    font-size: 0.925rem;
  }

  body #contact .contact-bar span {
    font-size: 0.925rem;
  }

  body #home .home-welcome-bar h3 {
    font-size: 1.725rem;
    margin-bottom: -20px;
  }

  body #home .home-welcome-bar h4 {
    font-size: 2.225rem;
  }


  #top-bar .btn-ticket {
    display: none;
  }


  body #history .body,
  body #contact .body,
  body #infos .body,
  body #festival .body,
  body #tickets .body,
  body #lineup .body {
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {

  body #contact .contact-bar {
    margin-bottom: 50px;
  }

  body #festival .container-list {
    padding-bottom: 30px;
  }

  body #festival .container-list>.row.odd .container .row>div:nth-child(2) {
    text-align: center;
  }

  body #festival .container-list>.row.even .container .row>div:nth-child(2) {
    order: 2;
    text-align: center;
  }

  body #festival .container-list>.row.even .container .row>div:nth-child(1) {
    order: 2;
  }

  body #artist .social-bar h3 {
    font-size: 1.125rem;
  }

  body #home .home-lineup-bar {
    display: none;
  }

  body #contact .contact-bar span {
    margin-bottom: 30px;
  }

  body #home .home-lineup-bar h6 a {
    font-size: 2.225rem;
    line-height: 3.525rem;
  }


  #history iframe {
    max-width: 100%;
  }

  #festival .container-list .container .row {
    margin-bottom: 50px;
  }

}

@media (max-width: 767px) {

  .page {
    padding-top: 50px;
  }

  body #tickets .ticket-grid .row.odd>div:nth-child(2) {
    order: 1;
    text-align: center;
    margin-bottom: 0px;
  }

  body #tickets .ticket-grid ul {
    text-align: left;
  }

  body #tickets .ticket-grid .call-to-action {
    margin-bottom: 30px;
  }

  body #tickets .ticket-grid .row.odd>div:nth-child(2) .call-to-action {
    margin-bottom: 30px
  }

  body #tickets .ticket-grid .row.odd>div:nth-child(1) {
    order: 0;
  }

  body #tickets .ticket-grid .row.even>div:nth-child(2) {
    text-align: center;
    order: 1;
  }

  body #home .home-lineup-bar h6 a {
    font-size: 1.225rem;
    line-height: 2.225rem;
  }

  body .stroke-headline-bar-01,
  body .stroke-headline-bar-02 {
    display: none!important;
  }
}

@media (max-width: 575px) {

  #home .home-welcome-bar h1 {
    font-size: 1.325rem!important;
  }

  #home video {
    left: -100%;
    position: relative;
  }

  #content-wrapper h1 {
    font-size: 1.325rem;
  }

  body #footer-partner-bar .swiper-slide .btn {
    font-size: 0.725rem;
  }


  body #home .arrow-bar svg {
    vertical-align: middle;

    display: block;
    margin: auto;

  }

  body #home .arrow-bar button {
    width: auto!important;
    height: auto!important;
    margin-bottom: 30px;
  }

  body #home .arrow-bar {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    left: 0px;
    right: 0px;
    margin: 0px;
  }

  body #home .countdown-bar {
    max-width: 100%;
  }

  body #home .countdown-bar h2 {
    font-size: 3.825rem;
  }

  body #home .countdown-item {
    font-size: 35px;
    width: 90px;
    height: 90px;
  }

  body #history iframe {
    margin-bottom: 70px;
    height: 290px;
  }

  #footer-info-bar .newsletter {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  #footer-info-bar .newsletter button {
    font-size: 1.025rem;
  }

  body #footer-info-bar .newsletter input:nth-child(2) {
    margin-left: 0px;
  }

  body #footer-info-bar .newsletter input {
    width: 100%;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }

  body #home .home-welcome-bar {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  body {
    padding-top: 40px;
  }

  body .fullscreen-menu .social-bar h2 {
    font-size: 1.925rem;
  }

  body .fullscreen-menu .terms-bar li a {
    font-size: 0.925rem;
  }

  body .fullscreen-menu .menu-bar li a,
  body .fullscreen-menu .menu-bar li {
    font-size: 1.725rem;
  }

  body .fullscreen-menu .social-bar {
    padding-bottom: 10px;
  }

  body .fullscreen-menu .social-bar li,
  body #footer-info-bar .social-bar li {
    width: 50px;
    height: 50px;
  }

  body .fullscreen-menu .close-btn {
    right: 75%;
  }

  body #top-bar .logo {
    width: 100px;
  }

  body #top-bar .col-sm-4 {
    flex: 0 0 auto!important;
    width: 33.3333333333%!important;
  }

  body .fullscreen-menu .social-bar h2 {
    margin-bottom: 10px;
    font-size: 1.725rem;
  }

  body .fullscreen-menu hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

}
