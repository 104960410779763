body.menu-open {
  overflow: hidden;
  height: 100vh;
}

.fullscreen-menu .animatesTriangleLeft svg {
  width: 1000px;
  height: 1000px;
}

.fullscreen-menu {
  z-index: 1
}

.fullscreen-menu hr {
  max-width: 400px;
  margin: auto;
}

.fullscreen-menu .animatesTriangleLeft {
  position: fixed;
  left: -350px;
  bottom: -210px;
  z-index: 0;
}

.fullscreen-menu .animatesTriangleRight svg {
  width: 1000px;
  height: 1000px;
}

.fullscreen-menu .animatesTriangleRight {
  position: fixed;
  right: -400px;
  top: -170px;
  z-index: 0;
}

.fullscreen-menu .logo-bar {
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;
}

.fullscreen-menu .logo-bar img {
  max-width: 200px;
}

.fullscreen-menu .headline-bar {
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.fullscreen-menu .headline-bar {
  font-weight: 800;
  font-size: 7.526rem;
  color: #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-family: futura-pt, sans-serif;
}

.fullscreen-menu hr {
  margin-top: 40px;
  margin-bottom: 40px;
}

.fullscreen-menu .menu-bar {
  padding-bottom: 0px;
  position: relative;
  z-index: 1;
}

.fullscreen-menu .menu-bar li {
  font-size: 3.225rem;
  color: #fff;
  letter-spacing: 0.425rem;
  -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: #fff;
  transition: all .5s ease;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.fullscreen-menu .menu-bar li a {
  font-size: 3.225rem;
  color: #fff;
  letter-spacing: 0.425rem;
  -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: #fff;
  transition: all .5s ease;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.fullscreen-menu .menu-bar li:hover a {
  color: #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.fullscreen-menu .social-bar {
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}

.fullscreen-menu .social-bar ul {
  margin-top: -1px!important;
}

.fullscreen-menu .social-bar li {
  border: 1px #fff solid;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 -1px -1px 0;
  transition: all 0.2s var(--circ-in-out);
}

.fullscreen-menu .social-bar li:hover {
  background-color: #fff;
  color: #000;
}

.fullscreen-menu .social-bar li:hover a {
  color: #000;
}

.fullscreen-menu .social-bar li a {
  transition: all 0.5s ease-in;
  font-size: 1.625rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
}

.fullscreen-menu .social-bar a {
  color: #fff;
}


.fullscreen-menu .social-bar h2 {
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.125rem;
  font-size: 2.325rem;
  margin-bottom: 30px;
}

.fullscreen-menu .close-btn {
  position: fixed;
    right: 50%;
    top: 200px;
    color: #000!important;
    background-color: #fff!important;
    width: 30px;
    height: 30px;
    padding: 0px;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    /* display: block; */
    border-radius: 100%;
    margin-left: 100px!important;
    transform: translateX(250px)
}

.fullscreen-menu .close-btn svg {
  fill: #000;
  width: 30px;
  height: 30px;
}

.fullscreen-menu .open-btn {
  color: #fff !important;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.125rem;
  font-size: 1.225rem !important;
  float: right;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
}

.fullscreen-menu .open-btn svg {
  fill: #d90000;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  color: #d90000;
  margin-right: 15px;
}

.fullscreen-menu .terms-bar  {
  position: relative;
  z-index: 1;
}

.fullscreen-menu .terms-bar li {
  font-size: 1.225rem;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
}

.fullscreen-menu .terms-bar li a {
  color: #6a6a6a;
  text-decoration: none;
}
