#festival {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#festival .body {
  padding-bottom: 50px;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

#festival .festival-headline {
  position: absolute;
  left: 0%;
  right: 50%;
  top: 50%;
  margin-top: -160px;
  z-index: 0;
}

#festival  .col-md-12 {
  position: relative;
}

#festival  .col-md-12 .container {
  background: #fff;
  z-index: 0;
  position: relative;
}


#festival .container-list {
  padding-bottom: 100px;
}

#festival .container-list h3 {
  width: 100%;
}

#festival .container-list .container .row>div {
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  flex-wrap: wrap;
}

#festival .container-list>.row.even .container .row>div:nth-child(1) {
  order: 1;
}

#festival .container-list>.row.even .container .row>div:nth-child(2) {
  order: 0;
  text-align: right;
}

#festival .container-list>.row.even .festival-headline {
  left: 50%;
  right: 0%;
}

#festival .container-list img {
  width: 100%;
  max-width: none;
}

#festival .festival-headline  h1 {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.325rem;
  margin-bottom: 0px;
  color: transparent;
  font-weight: 800;
  font-size: 3.926rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d90000;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  padding-left: 7px;
  padding-right: 7px;
  text-decoration: none;
  background-color: #FFF;
}

#festival .festival-headline  h1.fill {
  color: #d90000;
}

#festival h2 {
  background-color: #d90000;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#festival .container-list {
  text-align: left;
}


#festival .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#festival .animatesTriangleLeft {
  position: absolute;
  left: -750px;
  top: -500px;
}


#festival .animatesTriangleRight {
  position: absolute;
  top: -350px;
  right: 0px;
  overflow: hidden;
  width: 400px;
}


.festival-plan-bar img {
  max-width: 700px;
  margin: auto;
  width: 100%;
}
