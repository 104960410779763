#covid {
}

#covid strong {
  color: #d90000;
}

#covid ul {
  padding: 0px;
  padding-left: 15px;
}
