#infos {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#infos .body {
  padding-bottom: 100px;
  max-width: 800px;
}


#infos .accordion-item {
  border-radius: 0px!important;
  border: none;
  margin-bottom: 10px;
}

#infos .accordion {
  text-align: left;
  margin-bottom: 100px;
}

#infos .accordion-header button.collapsed {
  background-color: #f1f1f1;
  color: #000;
  border: none!important
}

#infos .accordion-header button.collapsed img {
  background-color: transparent;
}

#infos .accordion-header button img {
  margin-right: 20px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  padding: 6px;
}


#infos .accordion-header button::after {
  fill: #fff;
}

#infos .accordion-header button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#infos .accordion-header button.collapsed:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}


#infos .accordion-header button {
  background-color: #d90000;
  border-radius: 0px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.125rem;
  font-size: 1.125rem;
  outline: none!important;
  box-shadow: none;
  z-index: 0;
}

#infos .accordion-body .btn {
  border: none;
  border-radius: 0px;
  margin-top: 10px;
  font-weight: 600;
  background-color: #d90000;
  color: #fff;
}

#infos .accordion-body img {
  max-width: 100%;
}

#infos .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#infos .animatesTriangleLeft {
  position: absolute;
  left: -750px;
  top: 00px;
}


#infos .animatesTriangleRight {
  position: absolute;
  top: -200px;
  right: 0px;
  overflow: hidden;
  width: 400px;
}
