#tickets {
  padding-top: 50px;
  width: 100%;
}

#tickets .body {
  padding-bottom: 100px;
  max-width: 800px;
}

#tickets .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#tickets .animatesTriangleLeft {
  position: absolute;
  left: -750px;
  top: 00px;
}


#tickets .animatesTriangleRight {
  position: absolute;
  top: -200px;
  right: 0px;
  overflow: hidden;
  width: 400px;
}


#tickets .ticket-grid {
  text-align: left;
  padding-bottom: 100px;
  background-color: #fff;
}


#tickets .ticket-grid h2 {
  color: #d90000;
  font-size: 1.725rem;
  font-weight: 700;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

#tickets .ticket-grid .btn {
  border: none;
  border-radius: 0px;
  background-color: #d90000;
  color: #fff;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 1.325rem;
  font-weight: 700;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 0.125rem;
}

#tickets .ticket-grid .price {
  font-weight: 700;
  font-size: 1.625rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: proxima-nova, sans-serif;
}

#tickets .ticket-grid .btn svg {
  margin-right: 10px;
}

#tickets .ticket-grid .body {
  padding: 0px;
}

#tickets .ticket-grid .call-to-action {
  margin-bottom: 80px;
}

#tickets .ticket-grid .row>div:nth-child(2) {
}

#tickets .ticket-grid .row.even>div:nth-child(1) {
  order: 1;
}

#tickets .ticket-grid .row.even>div:nth-child(2) {
  order: 0;
  text-align: right;
}

#tickets .ticket-grid .row.odd>div:nth-child(1) {
  text-align: right;
}
