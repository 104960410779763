#contact {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#contact .body {
  padding-bottom: 100px;
  max-width: 800px;
  margin: auto;
}


#contact h2 {
  background-color: #d90000;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
}


#contact .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#contact .animatesTriangleLeft {
  position: absolute;
  left: -750px;
  top: 00px;
}


#contact .animatesTriangleRight {
  position: absolute;
  top: -200px;
  right: 0px;
  overflow: hidden;
  width: 400px;
}


#contact  .contact-bar {
  margin-bottom: 150px;
}

#contact  .contact-bar span {
  display: block;
  text-align: center;-webkit-box-shadow: 0px 0px 49px 0px rgba(0,0,0,0.1);
-moz-box-shadow: 0px 0px 49px 0px rgba(0,0,0,0.1);
box-shadow: 0px 0px 49px 0px rgba(0,0,0,0.1);
padding: 10%;
-webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
}

#contact  .contact-bar span:hover {
  background-color: #e20a17;
  color: #fff;
}

#contact  span svg {
  display: block;
  margin: auto;
  font-size: 3.925rem;
  color: #e20a17;
  margin-bottom: 20px;

  -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

#contact  span:hover svg {
  color: #fff;
}
