#history {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#history .body {
  padding-bottom: 100px;
  max-width: 800px;
  text-align: center;
  margin: auto;
}


#history h2 {
  color: #d90000;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
}

#history  iframe {
  margin-bottom: 150px;
}


#history .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#history .animatesTriangleLeft {
  position: absolute;
  left: -750px;
  top: 00px;
}


#history .animatesTriangleRight {
  position: absolute;
  top: -200px;
  right: 0px;
  overflow: hidden;
  width: 400px;
}
