/**
 * page styles
 *
*/
#home {
}

#home .covid-bar {
  position: absolute;
  bottom: 450px;
  left: 50%;
  z-index: 100;
  width: 500px;
  text-align: center;
  margin-left: -250px;
}



#home .covid-bar .btn {
  border-radius: 0px;
    border: none;
    background-color: #d90000;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.225rem;
    letter-spacing: 0.125rem;
    color: #fff;
    font-weight: 400;
}

#home .intro-bar {
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  max-height: 1100px;
  height: 1100px;
  background-color: #000;
}

#home .intro-bar .bg-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  background-color: #000;
  z-index: 0;
  display: none;
}

#home .intro-bar img {
  width: 100%;
  min-height: 100%;
}

#home .stroke-headline-bar-01 {
  position: absolute;
  top: 20%;
  right: 50%;
  z-index: 1;
  white-space: nowrap;
  display: grid;
  justify-content: flex-end;
  width: 50%;
  opacity: 0.5;
  -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

#home .stroke-headline-bar-01>div {
  width: 100%;
}

#home .stroke-headline-bar-01 div,
#home .stroke-headline-bar-01 span,
#home .stroke-headline-bar-01 h1 {
  color: transparent;
  font-weight: 800;
  font-size: 5.526rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-transform: uppercase;
  animation: ani 7s linear infinite;
  font-family: futura-pt, sans-serif;
}


#home .stroke-headline-bar-01:hover h1,
#home .stroke-headline-bar-01:hover span,
#home .stroke-headline-bar-01:hover div {
  color: #fff!important;
}

#home .stroke-headline-bar-02 {
  position: absolute;
  bottom: 50%;
  left: 50%;
  z-index: 1;
  white-space: nowrap;
  z-index: 1;
  white-space: nowrap;
  display: grid;
  justify-content: flex-end;
  width: 50%;
  opacity: 0.5;
  -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

#home .stroke-headline-bar-02>div {
  width: 100%;
}

#home .stroke-headline-bar-02 div,
#home .stroke-headline-bar-02 span,
#home .stroke-headline-bar-02 h1 {
  color: transparent;
  font-weight: 800;
  font-size: 5.526rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  animation: ani 7s linear infinite;
}


#home .stroke-headline-bar-02:hover h1,
#home .stroke-headline-bar-02:hover span,
#home .stroke-headline-bar-02:hover div {
  color: #fff!important;
}

#home .intro-bar .st2 {
}



@keyframes ani {
  0%{
     color: transparent;
     text-shadow: none;
     -webkit-text-stroke-width: 1px;
  }
  50%{
    color:#fff;
    -webkit-text-stroke-width: 0px;
    text-shadow: 0 0 7px #fff,0 0 40px #fff;
  }
  100%{
    color: transparent;
    text-shadow: none;
    -webkit-text-stroke-width: 1px;
  }
}


#home .combined {
  color: #fff;
  width: 316px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}


#home .combined .title {
  font-size: 58px;
  letter-spacing: 8px;
  position: absolute;
  width: 350px;
  overflow: hidden;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#home .combined-bar {
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 70px;
}

#home .combined .content {
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 78px;
}






#home .countdown-bar {
  position: absolute;
  bottom: 10%;
  left: 0px;
  right: 0px;
  z-index: 1;
  width: 405px;
  margin: auto;

  opacity: 1!important;

  -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

#home .countdown-bar:hover {
  opacity: 1!important;
}

#home .countdown-bar h2 {
  color: #fff;
  font-size: 4.525rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 4.625rem;
  margin-bottom: 0px;
}

#home .countdown-bar h3 {
  color: #fff;
  padding: 2px;
  background-color: #d90000;
  font-size: 2.825rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: auto;
  margin-top: 10px;
  width: 90%;
}

#home .countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#home .countdown-item>* {
  z-index: 1;
}

#home .countdown-item {
  color: #fff;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 0px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
  z-index: 1;
  font-weight: 800;
  text-align: center;
}

#home .countdown-item span {
  color: #fff;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

#home .countdown-svg {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  fill: #fff;
  color: #fff;
  z-index: -1;
}

#home .efaGep {
  display: none;
}




#home .video-bar {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 0;
  background-color: #000;
  height: 100%;
}

#home .video-bar video {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.5;
  filter: grayscale(1)
}



#home .arrow-bar {
  position: absolute;
  bottom: 0px;
  left: 50%;
  font-size: 2.525rem;
  color: #fff;
  z-index: 5;
  margin-left: -40px;
}

#home .arrow-bar button {
  background: none !important;
  border: none !important;
  color: #fff !important;

}








#home .triangle-bar {
  position: fixed;
  z-index: 0;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  overflow: hidden;

}

#home .triangle-bar .animatesTriangleLeft {
  bottom: 0px;
}

#home .triangle-bar .animatesTriangleLeft {
  position: absolute;
  left: -650px;
  bottom: -410px;
}

#home .triangle-bar .animatesTriangleRight {
  position: absolute;
  right: -600px;
  top: -480px;
}



#home .home-welcome-bar {
  background-color: #fff;
  position: relative;
  z-index: 0;
  padding-top: 100px;
  padding-bottom: 100px;
}

#home .home-welcome-bar .icon-bar {
  font-size: 3.225rem;
  color: #d90000;
  margin-bottom: 30px;
}

#home .home-welcome-bar h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.825rem;
  letter-spacing: 0.325rem;
}

#home .home-welcome-bar h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.125rem;
  letter-spacing: 0.325rem;
  margin-bottom: 30px;
}


#home .home-welcome-bar h3 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.125rem;
  letter-spacing: 0.325rem;
  margin-bottom: -30px;
  color: #d90000;
  position: relative;
  z-index: 2;
}

#home .home-welcome-bar h4 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.125rem;
  letter-spacing: 0.325rem;
  margin-bottom: 30px;
  color: transparent;
  font-weight: 800;
  font-size: 3.526rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  position: relative;
  z-index: 1;
}



#home .home-welcome-bar .body {
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
}



#home .home-welcome-bar .animatesTriangleRight {
  position: absolute;
  right: -400px;
  display: none;
  bottom: 0px;
}




#home .home-welcome-bar .fill,

#home .home-welcome-bar.fill polygon {
  fill-opacity: 1;
  stroke-width: 0px;
  transition: all 1s ease-in;
}

#home .home-welcome-bar .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#home .home-welcome-bar .animatesTriangleRight svg {
  width: 700px;
  height: 700px;
}








#home .home-lineup-bar {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff;
}

#home .home-lineup-bar h6 {
  margin: 0px;
  margin-bottom: -10px;
}

#home .home-lineup-bar h6 a {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.325rem;
  margin-bottom: 0px;
  color: transparent;
  font-weight: 800;
  font-size: 3.926rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  padding-left: 7px;
  padding-right: 7px;
  text-decoration: none;
}



#home .home-lineup-bar h6 a:hover {
  color: #000;
}

#home .home-lineup-bar .artist-detail {
  position: absolute;
  top: 120px;
  left: 50%;
  margin-left: -150px;
  max-width: 300px;
}

#home .home-lineup-bar h3 {
  text-transform: uppercase;
  font-size: 2.325rem;
  font-weight: 600;
  letter-spacing: 0.225rem;
  margin-bottom: 40px;
}


#home .home-lineup-bar .btn {
  background: none;
  border: none;
  border-radius: 0px;
  color: #000;
  margin-top: 30px;
  letter-spacing: 0.125rem;
  font-size: 1.425rem;
  font-weight: 600;
  text-transform: uppercase;
}

#home .home-lineup-bar .btn svg {
  margin-right: 10px;
}


#covidModal .animatesTriangleRight,
#covidModal .animatesTriangleLeft {
  display: none;
}

#covidModal .page {
  padding-top: 0px;
  padding-bottom: 0px;
}

#covidModalLabel {
  display: none;
}
